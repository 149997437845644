import { Navigate, RouteObject } from 'react-router-dom'

import { AccessType } from '@/types'
import { RoutePath } from '@/router/enums'

import { landingRoutes } from '@/router/landingRoutes'
import { panelRoutes } from '@/router/panelRoutes'
import { serviceRoutes } from '@/router/serviceRoutes'
import { ssoRoutes } from '@/router/ssoRoutes'

const routes = (access: AccessType, isAutoTester: boolean): RouteObject[] => {
  const { isAuthenticated } = access

  return [
    ...panelRoutes,
    ...ssoRoutes(access),
    ...landingRoutes,
    {
      index: true,
      element: <Navigate to={`${RoutePath.Auth}`} />,
      path: RoutePath.Home
    },
    // oidc после логина перебрасывается на урл /callback,
    // поэтому делаем редирект на auth для выполнения стандартного флоу
    {
      element: <Navigate to={`/${RoutePath.Auth}`} />,
      path: RoutePath.Callback
    },
    // TODO: Добавлен алиас пока не беке не передают редирект на страницу Consent с /consent на /auth/consent
    {
      element: <Navigate to={`/${RoutePath.Auth}/${RoutePath.Consent}`} />,
      path: RoutePath.Consent
    },
    // TODO: Добавлен алиас, пока не переeхали с passmedia
    {
      element: <Navigate to={`/${RoutePath.UserOffice}/${RoutePath.Settings}`} />,
      path: RoutePath.ChangePassword
    },
    ...serviceRoutes(isAuthenticated, isAutoTester)
  ]
}

export default routes
