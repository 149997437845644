import * as React from 'react'
import { RouteObject } from 'react-router-dom'

import { RoutePath } from '@/router/enums'

import MainLayout from '@/layouts/MainLayout'
import SsoLayout from '@/layouts/SsoLayout'

const Main = React.lazy(() => import('@/pages/SsoUserPanel/Main'))
const Services = React.lazy(() => import('@/pages/SsoUserPanel/Services'))
const ProfileSection = React.lazy(() => import('@/pages/SsoUserPanel/Profile/ProfileSection'))
const Profile = React.lazy(() => import('@/pages/SsoUserPanel/Profile'))
const EditProfile = React.lazy(() => import('@/pages/SsoUserPanel/Profile/EditProfile'))
const Settings = React.lazy(() => import('@/pages/SsoUserPanel/Settings'))
const MainSettings = React.lazy(() => import('@/pages/SsoUserPanel/Settings/MainSettings'))
const EmailList = React.lazy(() => import('@/pages/SsoUserPanel/Settings/EmailList'))
const RemoveAccount = React.lazy(() => import('@/pages/SsoUserPanel/Settings/RemoveAccount'))
const LoginHistory = React.lazy(() => import('@/pages/SsoUserPanel/Settings/LoginHistory'))
const ChangePassword = React.lazy(() => import('@/pages/Sso/ChangePassword/ChangePassword'))
const RecoveryOTP = React.lazy(() => import('@/pages/Sso/RecoveryOTP/RecoveryOTP'))
const SetPassword = React.lazy(() => import('@/pages/SsoUserPanel/SetPassword'))
const Logout = React.lazy(() => import('@/pages/Sso/Logout'))

export const panelRoutes: RouteObject[] = [
  {
    element: <MainLayout />,
    path: RoutePath.UserOffice,

    children: [
      {
        element: <Main />,
        index: true,
        path: RoutePath.MainPage
      },
      {
        element: <Services />,
        path: RoutePath.Services
      },
      {
        element: <Profile />,
        path: RoutePath.Profile,

        children: [
          {
            element: <ProfileSection />,
            index: true
          },
          {
            element: <EditProfile />,
            path: RoutePath.Change
          }
        ]
      },
      {
        element: <Settings />,
        path: RoutePath.Settings,

        children: [
          {
            element: <MainSettings />,
            index: true
          },
          {
            element: <EmailList />,
            path: RoutePath.SettingsEmail
          },
          {
            element: <RemoveAccount />,
            path: RoutePath.SettingsRemoveAccount
          },
          {
            element: <LoginHistory />,
            path: RoutePath.SettingsLoginHistory
          }
        ]
      }
    ]
  },
  {
    element: <SsoLayout isOffice />,
    path: RoutePath.UserOffice,

    children: [
      {
        element: <ChangePassword isOffice />,
        path: RoutePath.ChangePassword
      },
      {
        element: <SetPassword />,
        path: RoutePath.SetPassword
      },
      {
        element: <RecoveryOTP />,
        path: RoutePath.RecoveryOTP
      },
      {
        element: <Logout />,
        path: RoutePath.Logout
      }
    ]
  }
]
