import * as React from 'react'
import { Action } from 'history'
import { Outlet, useLocation } from 'react-router-dom'

import UiLoader from '@/ui/components/UiLoader'
import { useThemeState, useThemeUpdater } from '@/ui/contexts/themeContext'
import RestartSessionRedirect from '@/components/RestartSessionRedirect'
import ThemeSwitcher from '@/components/ThemeSwitcher'
import { useHistory } from '@/router/components/BrowserRouter'

import { useBrandBackground } from '@/hooks/useBrendBackground'
import useCurrentRouteName from '@/hooks/useCurrentRouteName'
import { usePlaywrightTestStatus } from '@/hooks/usePlaywrightTestStatus'

import { FIRST_STEP_PATHS_ALLOWED } from './constants'
import { THEME } from '@/pages/Sso/Auth/constants'
import { SsoLayoutLocationStateType } from './types'
import { RoutePath } from '@/router/enums'

import { FlexBoxCentered, Layout } from './SsoLayout.style'

import { useBrandingSelector } from '@/store/brandingStore'
import { useClientInfo, useClientInfoSelector } from '@/store/clientInfoStore'
import { usePrincipalStoreSelector } from '@/store/principalStore'

type PropsType = {
  isOffice?: boolean
}

const SsoLayout = ({ isOffice = false }: PropsType) => {
  const { setBrandBackground } = useBrandBackground()

  const resetPrincipalInfo = usePrincipalStoreSelector.use.resetPrincipalInfo()
  const clientInfoStoreStatus = useClientInfoSelector.use.status()
  const brandingStoreStatus = useBrandingSelector.use.status()

  const pageName = useCurrentRouteName()

  const { setTheme } = useThemeUpdater()
  const currentTheme = useThemeState()

  const location = useLocation()
  const { state: locationState } = location as { state: SsoLayoutLocationStateType }

  const clientInfo = useClientInfo()
  const isShowLoader =
    clientInfoStoreStatus === 'loading' ||
    clientInfoStoreStatus === 'needInit' ||
    brandingStoreStatus === 'loading' ||
    brandingStoreStatus === 'needInit'

  const history = useHistory()

  const isPlaywrightTest = usePlaywrightTestStatus()

  React.useEffect(() => {
    setBrandBackground(clientInfo)
  }, [clientInfo, setBrandBackground])

  React.useEffect(() => {
    if (clientInfo?.theme && currentTheme !== clientInfo.theme) {
      setTheme(clientInfo.theme)

      // Запомнить темную тему для setPassword view
      localStorage.setItem(THEME, JSON.stringify(clientInfo.theme))
    }
  }, [clientInfo, setTheme, currentTheme])

  React.useEffect(() => {
    if (history) {
      // // если пробуем попасть на страницу '/Auth' путем нажатия на кнопку 'назад' браузера, очищаем state и релоадим страницу
      history.listen(({ location, action }) => {
        if (action === Action.Pop && location.pathname === `/${RoutePath.Auth}`) {
          resetPrincipalInfo()
          window.location.reload()
        }
      })
    }
  }, [history, resetPrincipalInfo])

  const isGetSsoLayout = locationState?.isGetSsoLayout
  const isNeedToOpenPage =
    FIRST_STEP_PATHS_ALLOWED.includes(window.location.pathname) || isPlaywrightTest || locationState?.isTrusted

  if (!clientInfo && !isGetSsoLayout && !isNeedToOpenPage && !isOffice) {
    return <RestartSessionRedirect caller='SsoLayout' />
  }

  const fallback = (
    <FlexBoxCentered>
      <UiLoader />
    </FlexBoxCentered>
  )

  return (
    <Layout data-pagename={pageName}>
      {isShowLoader ? (
        fallback
      ) : (
        <React.Suspense fallback={fallback}>
          <Outlet />
        </React.Suspense>
      )}

      <ThemeSwitcher isOn={false} />
    </Layout>
  )
}

export default SsoLayout
